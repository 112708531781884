import { useLocation, useNavigate } from "react-router-dom";
import Header from './../components/Header.jsx';
import Confetti from './../components/Confetti.jsx';

import chipGuy from '../img/sf_chip_guy.png';
import pringlesCanMan from '../img/sf_athletic_can.png';
import musicNote from '../img/sf_beamed_eighth.png';

function QuizResults({ data }) {
    const webShareSupported = 'canShare' in navigator
    const publicURL = `${process.env.PUBLIC_URL}/results`
    const { results } = data

    const navigate = useNavigate();
    let { state } = useLocation();

    if ( null === state  ) window.location='/';

    const { fromSpotify, user } = state || {};
    const result = ( state || {} )['result'] || 'original'

    const resultImg = results[result];

    if(!resultImg) window.location='/';

    const { description, imgSlug } = resultImg;
    const imgDefaultSrc = `${publicURL}/${imgSlug}_1080.jpg`

    const shareOrDownload = async (blob, fileName, shareTitle, text) => {
      // Using the Web Share API.
      if (webShareSupported) {
        const data = {
          files: [
            new File([blob], fileName, {
              type: blob.type,
            }),
          ]
          // -- Can't preopulate text on Insta, which is the target
          // ,
          // text
          // title: shareTitle
        };
        if (navigator.canShare(data)) {
          try {
            await navigator.share(data);
          } catch (err) {
            if (err.name !== 'AbortError') {
              console.error(err.name, err.message);
            }
          } finally {
            return;
          }
        }
      } else {
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          setTimeout(() => {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
          }, 0)
      }
    }

    async function shareIt() {
      const blob = await fetch(imgDefaultSrc).then(res => res.blob());
        await shareOrDownload(blob, `${result}.jpg`, '', '');
    }

    const { title, playlistID } = resultImg;

    const actions = fromSpotify
            ? null
            : (<button onClick={takeQuizAgain} className="button grey-button">
                Try Again
               </button>);

    const imgTag = <img
      onClick={ shareIt }
        className={'result-img ' + title }
        alt={ title }
        srcSet={`${publicURL}/${imgSlug}_800.webp 800w, ${publicURL}/${imgSlug}_1080.webp 1080w`}
        src={ imgDefaultSrc } />

    function takeQuizAgain() {
        navigate("/select-artists", { state: { user } })
    }

    // Shuffle array
    // const shuffled = resultImg.artists.sort(() => 0.5 - Math.random());
    // let randomArtists = shuffled.slice(0, 10).map((art) => art.name).join(', ');

    return (
        <>
          <Header className="page-splash" />

          <div id="app" className="App-template-wrapper">
              <div className="Results-wrapper">
                  <div className="Results-inner-wrapper Original-wrapper">
                      <div className="Results-text-wrapper">
                          <div className="loading-characters characters-results">
                            <img className="chip-guy" src={chipGuy} alt="Chip in a cap carrying a boom box rocking out to some tunes"/>
                            <img className="pringles-can-man" src={pringlesCanMan} alt="Yellow Pringles can with legs jumping" />
                            <img className="music-note-1" role="presentation" src={musicNote} alt="Wiggling music note" />
                            <img className="music-note-2" role="presentation" src={musicNote} alt="Wiggling music note" />
                            <img className="music-note-3" role="presentation" src={musicNote} alt="Wiggling music note" />

                            <h1 className="title orange small">
                                { (user && user.name_first) || 'Congratulations' },
                            </h1>
                              <p>{ description }</p>
                          </div>

                          { imgTag }

                          <span className="important post-call">
                              Post to your Stories and tag <span className="">@pringles</span>
                          </span>

                          <button
                              onClick={shareIt}
                              className="button blue-button share">
                              { webShareSupported ? 'Share' : 'Download' }
                          </button>
                      </div>
                  </div>
              </div>

              <h3 className="title orange small"
                  style={{marginBottom: '1.5rem'}}
              >Playlist Made For You</h3>

              <iframe style={{borderRadius: '12px'}}
                  src={`https://open.spotify.com/embed/playlist/${playlistID}?utm_source=generator`}
                  width="100%"
                  title="Flavor Playlist"
                  height="352"
                  frameBorder="0"
                  allowFullScreen=""
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy">
              </iframe>

              <div className="App-content-outer-container">
                  <div className="App-content-inner-container">
                      { actions }
                  </div>
              </div>
              <small>Pringles does not own the rights to any music</small>
          </div>

        <Confetti />
      </>
);
}

export default QuizResults;
