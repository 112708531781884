import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Header from '../components/Header.jsx';

function Form() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [ errors, setErrors ] = useState(false);
  const { result, user, fromSpotify } = state

  function errorKlass(attr) {
    if (errors && Object.keys(errors).includes(attr)) {
      return 'has-error'
    }

    return ''
  }

  /**
   * Signs the user up, or optionally adds additional information to the user
   * before the submission/progression is allowed
   *
   */
  function submit(event) {
    event.preventDefault();

    if ( !process.env.REACT_APP_API_URL ||
      !process.env.REACT_APP_CAMPAIGN_TOKEN
    ) {
      console.error("No API URL configured");
      return false;
    }

    const user = {};
    const form = new FormData(event.target);
    form.forEach((value, key) => user[key] = value);

    if ( !fromSpotify ) {
      const email = (user.email || '').toLowerCase().trim()
      const emailConfirm = (user.confirm_email || '').toLowerCase().trim()

      if ( email.length === 0 ) { setErrors({email: 'Email is required'}) }
      if ( email !== emailConfirm ) { setErrors({confirm_email: 'Email must match'}) }

      if ( email.length === 0 || email !== emailConfirm ) {
        return false
      }
    }

    fetch(
      `${process.env.REACT_APP_API_URL}/signup`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Bearer': `${process.env.REACT_APP_CAMPAIGN_TOKEN}`
        },
        body: JSON.stringify({ user }),
      }
    ).then((resp) => {
      return resp.json()
    }).catch((resp) => {
      // TODO handle parsing error
    }).then((resp) => {
      if ( Object.keys(resp).includes('errors') ) {
        console.info('Error updating or creating user')
        setErrors(resp.errors.detail)
        return false;
      }

      // else - Everything was successful...
      navigate('/results', { state: { user, result, fromSpotify } })
    })
  }

  function defaultAttr(attr) {
    return (user && user[attr]) || '';
  }

  let errorsHTML = null
  if ( errors && Object.values(errors).length > 0 ) {
    errorsHTML = (
      <ul className="errors">
        {Object.keys(errors).map(function (key) {
          return (<li key={key} className="error">{errors[key]}</li>)
        })}
      </ul>
    )
  }

  let confirmEmailHTML = null
  if ( !fromSpotify ) {
    confirmEmailHTML = <input
      required
      defaultValue={defaultAttr('email')}
      className={errorKlass('confirm_email')}
      placeholder="Confirm email" type="email" name="confirm_email" />
  }

  const emailHTML = <input
                  key="input-email"
                  required
                  defaultValue={defaultAttr('email')}
                  className={errorKlass('email')}
                  placeholder="Email" type="email" name="email" />

  const dobHTML = <input
                  required
                  key="input-confirm-email"
                  defaultValue={defaultAttr('birthdate')}
                  className={errorKlass('birthdate')}
                  placeholder="Birthday" type="date" name="birthdate" />

  // const postalCodeHTML = <input
  //                 defaultValue={defaultAttr('postal_code')}
  //                 className={`zip ${errorKlass('postal_code')}`}
  //                 placeholder="ZIP Code" type="text" name="postal_code" />

  let dynHTML = [emailHTML, confirmEmailHTML]
  let detailsHTML = dynHTML

  if ( fromSpotify ) {
    detailsHTML = <div className="form-row">
      { dynHTML }
    </div>
  } else {
    detailsHTML = <>
      <div key="email-form-row" className="form-row">
        { emailHTML}
      </div>
      <div key="confirm-email-form-row" className="form-row">
        { confirmEmailHTML }
      </div>
      </>
  }

  return (
    <>
      <Header />
      <div className="App-template-wrapper">
        <div className="App-content-outer-container">
          <div className="App-content-inner-container">
            <form className="form" onSubmit={submit}>
              <h1 className="title small">
                Finish To See Your Flavor!
              </h1>
              { errorsHTML }
              <div className="form-row">
                <input
                  required
                  defaultValue={defaultAttr('name_first')}
                  placeholder="First Name" type="text" name="name_first" />
                <input
                  required
                  defaultValue={defaultAttr('name_last')}
                  placeholder="Last Name" type="text" name="name_last" />
              </div>
              { detailsHTML }
              <div className="form-row">
              { dobHTML }
              </div>
              <div className="form-row with-check">
                <input id="optIn" type="checkbox" name="opt_in_marketing" defaultChecked={false} />
                <label htmlFor="optIn">
                  I would like to receive product news, exciting offers & personalized content. I acknowledge my information will be used according to the&nbsp;
                  <a href="https://www.kelloggcompany.com/en_US/privacy-policy.html" target="_blank" rel="noreferrer">Kellogg Privacy Policy</a>
                </label>
              </div>
              <div className="actions">
                <button type="submit" className="button blue-button">
                  See My Result!
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Form;
