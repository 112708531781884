import React from "react";

import chipGuy from '../img/sf_chip_guy.png';
import pringlesCanMan from '../img/sf_athletic_can.png';
import musicNote from '../img/sf_beamed_eighth.png';

export default function Loader({ isInfinite }) {
  let isInfiniteLoader = isInfinite;
  if (isInfinite === undefined) {
    isInfiniteLoader = true;
  }

  return (
    <div className="loader">
      <h1 className="title orange small">Matching you with your perfect flavor</h1>

      <div className="loading-bar">
        <div className={`progress-indicator ${isInfiniteLoader ? 'infinite' : ''}`} />
      </div>

      <div className="loading-characters">
        <img className="chip-guy" src={chipGuy} alt="Chip in a cap carrying a boom box rocking out to some tunes"/>
        <img className="pringles-can-man" src={pringlesCanMan} alt="Yellow Pringles can with legs jumping" />
        <img className="music-note-1" role="presentation" src={musicNote} alt="Wiggling music note" />
        <img className="music-note-2" role="presentation" src={musicNote} alt="Wiggling music note" />
        <img className="music-note-3" role="presentation" src={musicNote} alt="Wiggling music note" />
      </div>
    </div>
  );
}
