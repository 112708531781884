import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";

import data from './data';
import Authorize from './pages/Authorize';
import Form from './pages/Form';
import SplashPage from './pages/SplashPage';
import Results from './pages/Results';
import SelectArtists from "./pages/SelectArtists";

function Router() {
  return (
    <Routes>
      <Route index element={<SplashPage />} />
      <Route path="/details" element={<Form />} />
      <Route path="/authorize" element={<Authorize />} />
      <Route path="/select-artists" element={<SelectArtists/>} />
      <Route path="/results" element={<Results data={data} />} />
      <Route path="/404" element={<NoMatch />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
 );
}


function NoMatch() {
  return (
    <div className="four-o-four">
      <h1 className="title">Nothing to see here!</h1>
      <p>
        <Link to="/" className="button">Go to the home page</Link>
      </p>
    </div>
  );
}

export default Router;
