import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import artistChoices from '../data/artist-choices';
import { calculateResult } from '../services/resultsService';

import Loader from '../components/Loader';
import Header from '../components/Header';


function SelectArtists() {
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let { state } = useLocation();
  const { user } = state || {};
  const navigate = useNavigate();

  const isNextEnabled = selectedArtists.length >= 3;
  const btnText = isNextEnabled ? 'Next' : `Select ${3 - selectedArtists.length} more`

  if(isLoading) {
    return (
      <>
        <Header />
        <Loader isInfinite={false}/>
      </>
    );
  }

  function nextClick() {
      if(isNextEnabled) {
        goToResults(selectedArtists);
      }
    }

    function goToResults(artists) {
      setIsLoading(true);
      setTimeout(() => {
        const result = calculateResult(artists);

        navigate(`/details`, { state: {result, user} });
        window.scrollTo(0,0);
      }, 3000);
    }

    function selectArtist(artist) {
      return () => {
        const alreadySelected = selectedArtists.some((sa) => sa.spotifyId === artist.spotifyId)
        if(alreadySelected) {
          const filteredSelectedArtists = selectedArtists.filter((sa) => sa.spotifyId !== artist.spotifyId)
          setSelectedArtists(filteredSelectedArtists)
          return;
        }

        const updatedSelectedArtists = [...selectedArtists, artist];
        // once they have selected 5 results, automatically send them to the results screen
        if(updatedSelectedArtists.length >= 5) {
        goToResults(updatedSelectedArtists);
        return;
      }

      setSelectedArtists(updatedSelectedArtists);
    }
  }

  return (
    <>
      <Header />
      <div className="Results-text-wrapper">
        <h1 className="title">Who are you listening to right now?</h1>
      </div>
      <div className="App-template-wrapper">
          <div className="App-content-outer-container">
            <div className="App-content-inner-container">
                <div className="artists-list-wrapper">
                  {
                    artistChoices.map((artist) => {
                      const selected = selectedArtists.some((sa) => artist.spotifyId === sa.spotifyId);
                      const className = `button artist-button ${selected ? 'selected' : ''}`;
                      return (
                        <button className={className}
                            key={artist.spotifyId}
                            onClick={selectArtist(artist)}>
                          {artist.name}
                        </button>
                      )
                    })
                  }
              </div>

              <div className="actions fixed">
                <button className="button orange-button"
                        disabled={!isNextEnabled}
                        onClick={nextClick}>
                  {btnText}
                </button>
              </div>
            </div>
          </div>
      </div>
    </>
  );
}

export default SelectArtists;
