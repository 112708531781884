import React from 'react';

import './App.scss';
import Router from './Router';
import { BrowserRouter } from 'react-router-dom';

// const urlParams = new URLSearchParams(window.location.search);
// const source = urlParams.get('utm_source');
//
// if ( source ) {
//   ReactGA.event({
//     category: "Traffic",
//     action: "Scan (Test)",
//     label: "Source", // optional
//     transport: "xhr", // optional, beacon/xhr/image
//   });
// }

function App() {
  return (
    <div className="App">
      <BrowserRouter>

        <div className="App-content">
          <div className="App-page">
            <Router />
            <footer className="footer">
          <p className="copyright">
              &reg;, &trade;, &copy; 2023 Kellogg NA Co. All rights reserved.
          </p>
          <div className="legal-links">
                  <a href="https://www.kelloggcompany.com/en_US/privacy-policy.html">Privacy Policy</a>
                  <span>&nbsp;|&nbsp;</span>
                  <a href="https://www.kelloggcompany.com/en_US/legal.html">Terms of Use</a>
          </div>
        </footer>
          </div>

        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
