import sourCreamArtists from './sour-cream.json';
import ranchArtists from './ranch.json';
import originalArtists from './original.json';
import cheddarSourCreamArtists from './cheddar-sour-cream.json';
import cheddarArtists from './cheddar.json';
import buffaloArtists from './buffalo.json';
import bbqArtists from './bbq.json';

const data = {
  results: {
    'sour-cream-onion': {
      title: 'Sour Cream & Onion',
      description: "You love a good duo. And Sour Cream and Onion are the duo that can't be denied. Better together than alone, their collaboration is an instant classic. And when unlikely flavors combine, magic happens.",
      imgSlug: 'sco',
      playlistID: '6Q0QdxVdVZpmwmaZKPjgMz',
      artists: sourCreamArtists,
      genres: [...new Set(sourCreamArtists.flatMap(a => a.genres))]
    },
    'ranch': {
      title: 'Ranch',
      description: "Indulge in the zesty ranch taste with your favorite hip hop tracks and let the music and munchies collide in a snacking symphony. With Pringles® Ranch Crisps, you'll be grooving to the rhythm of your favorite beats while satisfying your cravings with every crispy bite.",
      imgSlug: 'ranch',
      playlistID: '0ZDK0Chutu3BMkp75JqA10',
      artists: ranchArtists,
      genres: [...new Set(ranchArtists.flatMap(a => a.genres))]
    },
    'original': {
      title: 'Original',
      description: "You're a timeless music lover who appreciates the classics. You enjoy the tried-and-true favorites that never go out of style just like Pringles® Original Crisps with a flavor that's been a favorite for generations.",
      imgSlug: 'original',
      playlistID: '7nWB4zm0hdvO3fe6pBZzkj',
      artists: originalArtists,
      genres: [...new Set(originalArtists.flatMap(a => a.genres))]
    },
    'cheddar-sour-cream': {
      title: 'Cheddar Sour Cream',
      description: "Refined taste? Check. Sophisticated palate? Double-check. Pringles Cheddar and Sour Cream match your discerning vibe. Indulge in the rich, smoky flavor while grooving to soulful jam, It's a snacking symphony, where music and munchies harmoniously collide!",
      imgSlug: 'csc',
      playlistID: '3n0lFHxy0ywTCRUY3uVH4A',
      artists: cheddarSourCreamArtists,
      genres: [...new Set(cheddarSourCreamArtists.flatMap(a => a.genres))]
    },
    'cheddar': {
      title: 'Cheddar',
      description: "Just like your playlists, Pringles Cheddar Crisps hit just right every time. They are the perfect companion as you groove to your favorite artists. The zesty cheddar flavor perfectly complements your pop music vibes.",
      imgSlug: 'cheddar',
      playlistID: '09PWkROL5mwW3Qza5MVU68',
      artists: cheddarArtists,
      genres: [...new Set(cheddarArtists.flatMap(a => a.genres))]
    },
    'buffalo': {
      title: 'Buffalo',
      description: "You're a fiery music enthusiast who likes to turn up the heat! Just like Pringles® Scorching Buffalo, you enjoy bold flavors and high-energy music that make you want to dance and party all night long. You're not afraid to try new things and your playlist is always filled with the latest pop and dance hits.",
      imgSlug: 'buffalo',
      playlistID: '7MVcygFMREcTuCG2Y0k1HF',
      artists: buffaloArtists,
      genres: [...new Set(buffaloArtists.flatMap(a => a.genres))]
    },
    'bbq': {
      title: 'BBQ',
      description: "You're a country music lover with a taste for smoky and tangy. Just like Pringles® BBQ Crisps, you enjoy the rich flavors of new country music that are full of soul and stories. Yeehaw!",
      imgSlug: 'bbq',
      playlistID: '5SL23OWpUIndDuYcht6Uil',
      artists: bbqArtists,
      genres: [...new Set(bbqArtists.flatMap(a => a.genres))]
    },
  }
}

export default data;
