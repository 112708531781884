import React from "react";

export default function Header(props) {
  const baseURL = `${process.env.PUBLIC_URL}/banner`
  // const logo = `${baseURL}.webp`
  // const fallback = `${baseURL}.jpg`
  const logo_mobile = `${baseURL}_mobile.webp`
  const fallback_mobile = `${baseURL}_mobile.jpg`
  const svg = `${baseURL}.svg`

  return (
    <header className={`header ${props.className}`}>
      <img
        className="large"
        src={svg} alt='Pringles Find Your Flavor'
      />
      <img
        className="mobile"
        srcSet={logo_mobile}
        src={fallback_mobile} alt='Pringles Find Your Flavor'
      />
    </header>
  );
}
