import React, {useRef, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";

import { getAllSpotifyIDs, calculateGenreResults } from '../services/resultsService';

import Loader from '../components/Loader';
import Header from '../components/Header';


/**
 * Retrieve the user from the redirected user param, get data from the API
 * and navigate to the correct react view
 *
 */
function Authorize() {
  // --- API Constants -------------------------------
  const API_URL = process.env.REACT_APP_API_URL;
  const API_HEADERS = useMemo(() => {
    return {
    'Content-Type': 'application/json',
    'Bearer': `${process.env.REACT_APP_CAMPAIGN_TOKEN}`
  }}, []);

  // --- Setup -------------------------------
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const userID = searchParams.get('userID');

  let [ shouldNavigate, setShouldNavigate ] = useState(false);
  let [ user, setUser ] = useState(null);

  let isUserLoaded = useRef(false);
  let isResultsLoaded = useRef(false);

  // Fetch user once...
  useEffect(() => {
    if ( isUserLoaded.current ) return ;
    isUserLoaded.current = true;

    if ( !userID || userID === '' ) {
      setShouldNavigate(true)
    } else {
      fetch(
        `${API_URL}/accounts/${userID}`,
        { method: 'GET', headers: API_HEADERS }
      ).then((resp) => {
        switch ( resp.status ) {
          case 404:
            console.info('User not found with ID', userID)
            throw new Error("User not found")
          default:
            return resp.json()
        }
      }).catch((data) => {
        return setShouldNavigate(true);
      }).then((data) => {
        isUserLoaded.current = 1;
        return setUser(data)
      })
    }
  }, [API_HEADERS, API_URL, userID])


  // Fetch results once....
  useEffect(() => {
    if ( isUserLoaded.current !== 1 || isResultsLoaded.current ) return;
    isResultsLoaded.current = true;
    const subset = getAllSpotifyIDs()

    fetch(
      `${API_URL}/spotify/genres/score/${user.id}`,
      { method: 'POST', headers: API_HEADERS,
        body: JSON.stringify({ subset })}
    ).then((resp) => {
      switch ( resp.status ) {
        case 200:
          return resp.json();
        default:
          throw new Error("Error retrieving results")
      }
    }).catch((err) => {
      return setShouldNavigate(true);
    }).then((data) => {
      const result = calculateGenreResults(data)

      // select the results
      // let sorted = [];
      // for (const id in data) {
      //   sorted.push([id, data[id]]);
      // }
      // const t5 = (sorted.sort((a, b) => b[1] - a[1]) || []).slice(0, 250)
      // const mapped = t5.flatMap((id) => { return {'spotifyId': id[0]} })
      // const result = calculateResult(mapped)

      setTimeout(() => {
        window.scrollTo(0,0);
        navigate(`/details`, { state: {result, user, fromSpotify: true} });
      }, 2000);

    })
  }, [API_URL, API_HEADERS, user, navigate]);

  useEffect(() => {
    if ( shouldNavigate ) {
      return navigate('/');
    }
  }, [shouldNavigate, navigate])


  return (
    <>
      <Header />
      <Loader isInfinite={true} />
    </>
  );
}

export default Authorize;
