import data from '../data';

export function getAllSpotifyIDs() {
  const { results } = data;
  const ids =
    Object
    .values(results)
    .flatMap( result => result.artists )
    .map( obj => obj.spotifyId )

  return [...new Set(ids.flat())]
}

function score(answerArtistIds, resultToArtistIds) {
  const resultToScore = {};
  Object.keys(resultToArtistIds).forEach((key) => {
    const intersection = resultToArtistIds[key].filter(x => answerArtistIds.includes(x));
    resultToScore[key] = intersection.length;
  });

  return resultToScore;
}

function calculateArtistIdsForResults(results) {
  const resultToArtistIds = {};

  Object.keys(results).forEach((key) => {
    resultToArtistIds[key] = results[key].artists.map((artist) => artist.spotifyId);
  });

  return resultToArtistIds; // { 'sour-cream-onion': [111], 'ranch': [141], ... }
}

export function calculateGenreResults(genres) {
  const { results } = data
  let score = {}

  Object.keys(results).forEach(key => {
    score[key] = score[key] || 0

    Object.keys(genres).forEach(genre => {
      if (results[key]['genres'].includes(genre)) {
        score[key] = score[key] + genres[genre]
      }
    })
  })

  if ( Object.keys(score).length === 0 ) {return null}

  const [winningKey,] = Object.entries(score)
    .sort(([,a],[,b]) => a-b)
    .reverse()[0]

  return winningKey
}

export function calculateResult(answers) {
  const answerArtistIds = answers.map((answer) => {
    return answer.spotifyId;
  });  // => [111, 141, ...]

  const resultToArtistIds = calculateArtistIdsForResults(data.results);

  const resultToScore = score(answerArtistIds, resultToArtistIds);

  let maxResult = '';
  let maxScore = -1;

  Object.keys(resultToScore).forEach((key) => {
    const score = resultToScore[key];
    if(score > maxScore) {
      maxResult = key;
      maxScore = score;
    }
  });

  return maxResult;
};
